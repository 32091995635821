// https://propaganda-czy-falsz.github.io/
export default [
  "GOSPODARKA W OLIMPIJSKIEJ FORMIE",
  "BRUKSELA POZWALA HISZPANOM PAŁOWAĆ LUDZI, A NAM ZABRANIA ZWALCZAĆ KORNIKA DRUKARZA",
  "DELFIN PLATFORMY BUDZI KONTROWERSJE",
  "PIERWSZY PATRIOTYCZNY RZĄD W HISTORII POLSKI",
  "SPECJALNY PŁOT ZATRZYMA GROŹNĄ CHOROBĘ",
  "OSTATECZNY KONIEC PAŃSTWA TEORETYCZNEGO",
  "OBROŃCY PEDOFILÓW I ALIMENCIARZY TWARZAMI OPORU PRZECIWKO REFORMIE SĄDOWNICTWA",
  "LEWACY ZAATAKOWALI PAMIĘĆ O BOHATERZE",
  "MŁODZI LUDZIE STAWIAJĄ NA PIS",
  "TOTALNA KLĘSKA TOTALNEJ OPOZYCJI",
  "POLSKA GWARANTEM BEZPIECZEŃSTWA EUROPY",
  "POLSKA NAUKA DOFINANSOWANA NAJLEPIEJ W EUROPIE",
  '"WIADOMOŚCI" DALEKO PRZED KONKURENCJĄ',
  "ZNAKOMITE WYNIKI FINANSOWE STADNINY W JANOWIE",
  "REKORDOWY WZROST PKB ZASŁUGĄ PROGRAMU 500+",
  "KOMU PRZESZKADZA RADIO MARYJA?",
  "POLSKA NIEDOŚCIGNIONYM BASTIONEM CHRZEŚCIJAŃSTWA",
  "EUROPOSŁOWIE PO PRZECIWKO POLSCE",
  "POLSKA EKONOMICZNYM LIDEREM EUROPY",
  "HOMOPROPAGANDA W SZKOŁACH ZAGROŻENIEM DLA ROZWOJU DZIECI",
  "PARTYJNY HAREM RYSZARDA PETRU",
  "POLSKA WSCHODZĄCĄ POTĘGĄ",
  "PLATFORMA I SĘDZIOWIE BRONIĄ RELIKTÓW PRL",
  "UNIA ZAZDROŚCI POLSCE SILNEJ GOSPODARKI",
  "WYCHOWANIE SEKSUALNE ZAGROŻENIEM DLA KRAJU",
  "DZIĘKI POLITYCE RZĄDU PIS MAMY KOLEJNY REKORD WIELKIEJ ORKIESTRY ŚWIĄTECZNEJ POMOCY",
  "RZĄD SPRZYJA ROZWOJOWI NOWYCH TECHNOLOGII",
  "JAROSŁAW KACZYŃSKI OBNIŻA PENSJE POLITYKOM",
  "KOMU PRZESZKADZA SILNA TELEWIZJA PUBLICZNA?",
  "NOWOCZESNA TWIERDZI ŻE SAMOTNE MATKI NIE ZASŁUGUJĄ NA 500+",
  "RZĄD STAWIA NA ROZWÓJ NAUKI",
  "KOTY PREZESA POSTRACHEM OPOZYCJI",
  "RZĄD TUSKA KAPITULOWAŁ PRZED ROSJĄ",
  "ROSNĄ MURY TANICH MIESZKAŃ",
  "ŁÓŻKOWE WOJAŻE JURNEGO STEFANA ŚWIADECTWEM NIESZCZEROŚCI TOTALNEJ OPOZYCJI",
  "MINISTER MACIEREWICZ STAWIA NA MŁODYCH",
  "ZAGROŻONA DEMOKRACJA W NIEMCZECH",
  "KOMU PRZESZKADZA SIŁA POLSKIEJ WALUTY?",
  "SPEKTAKULARNY UPADEK GAZETY WYBORCZEJ",
  "PAŃSTWO TUSKA ODDAŁO 35 MLN ZŁ GANGSTEROM",
  "25 PRAWNIKÓW JEDZIE JUTRO DO BRUKSELI Z DONOSEM NA POLSKĘ",
  "UKRÓCENIE ZŁODZIEJSTWA ZAWSTYDZIŁO LIBERAŁÓW",
  "ARTYKUŁ GAZETY WYBORCZEJ TO KAPISZON",
  "MŁODZI LEKARZE SZKALUJĄ POLSKĘ NA ARENIE MIĘDZYNARODOWEJ",
  "TOTALNA OPOZYCJA ROBI ZAMACH NA WARTOŚCI RODZINNE",
  "RZĄD SPEŁNIŁ JUŻ PRAWIE WSZYSTKIE OBIETNICE",
  "REKORDOWA NADWYŻKA W BUDŻECIE PAŃSTWA",
  "CZYIM AGENTEM JEST RADOSŁAW SIKORSKI?",
  "MLECZNOŚĆ POLSKICH KRÓW ZATRWAŻA NIEMCÓW",
  "REFORMY RZĄDU JEDNOCZĄ POLAKÓW",
  "AGRESJA I CHAMSTWO - TYLE ZAPAMIĘTAMY Z DZISIEJSZEJ MANIFESTACJI PRZED SEJMEM",
  "ŚWIAT ZAZDROŚCI POLAKOM SUKCESU GOSPODARCZEGO",
  "MĄDROŚCI POSŁA GRABCA POD MONOPOLOWYM",
  "ANTYPOLSKA SZARŻA DONALDA TUSKA",
  "WIELKI SUKCES FESTIWALU W OPOLU",
  "TOTALNA OPOZYCJA I JEJ ZWIĄZKI Z ESBEKAMI",
  "PIS OBNIŻA PODATKI KTÓRE PODNOSIŁA PLATFORMA",
  "CZY NIEPEŁNOSPRAWNI MAJĄ CZYSTE INTENCJE?",
  "TOTALNA OPOZYCJA WRZODEM NA CIELE POLSKIEGO NARODU",
  'GAZETA WYBORCZA OCZERNIA "OJCA MATEUSZA"',
  "RZĄD ODDAŁ POLAKOM TO, CO ZABRAŁA PLATFORMA",
  "PLATFORMA OBYWATELSKA GARDZI KOBIETAMI?",
  "BRUKSELA ROBI ZAMACH NA SUWERENNOŚĆ POLSKI",
  "JAROSŁAW KACZYŃSKI: WYSTARCZY NIE KRAŚĆ",
  "KOLEJKI DO LEKARZY STAJĄ SIĘ PRZESZŁOŚCIĄ",
  "NOWOCZESNA I PLATFORMA SPISKUJĄ PRZECIWKO POLSCE",
  "STUDIO YAYO BAWI POLAKÓW DO ŁEZ",
  "GRABIEŻ BUDŻETU PAŃSTWA ZATRZYMANA",
  "PROGRAM 500+ POWODUJE ODRODZENIE POLSKI",
  "BRONIARZ I JEGO OŚWIATOWE GESTAPO BIORĄ UCZNIÓW JAKO ZAKŁADNIKÓW",
  "KOŚCIÓŁ KATOLICKI POD OBLĘŻENIEM HOMOLOBBY",
  "NAUCZYCIELE AGRESYWNIE ATAKUJĄ RZĄD",
  "JAK MANIPULUJE NIEMIECKA TELEWIZJA PUBLICZNA",
  "MORAWIECKI: TWORZYMY PAŃSTWO OPIEKUŃCZE",
  "WYCHOWANIE SEKSUALNE POWODUJE ROZPASANIE UMYSŁOWE",
  '"KORONA KRÓLÓW" PODBIJA ŚWIAT',
  "POLSKA TO KRAJ PŁYNĄCY MLEKIEM I MIODEM",
  "SIECI SOROSA OPLOTŁY UNIĘ EUROPEJSKĄ",
  "Wirus zostanie z nami na długo?",
  "Chwila prawdy dla Europy",
  "Podwójne standardy w brukseli",
  "Pytania o nagrody Grodzkiego",
  "Inwestycje uratują polską gospodarkę",
  "Świat walczy z pandemią",
  "Wychodzisz z domu, załóż maseczkę",
  "Epidemia nie może zniszczyć demokracji",
  "Sondaż: Rośnie poparcie dla Andrzeja Dudy",
  "1,5 mln zł dla urzędników Grodzkiego",
  "Zostań w domu z telewizją polską",
  "Medialna kampania nienawiści",
  "Apel do Polaków: Zostańcie w domach!",
  "Zostań w domu dla własnego bezpieczestwa",
  "Zostańcie w domach!",
  "Zostań w domu!",
  "Zostań w domu - chroń siebie i innych",
  "Zostań w domu pomimo ładnej pogody",
  "Chroń siebie i innych!",
  "Walka z wirusem trwa",
  "Wirus w Europie i na świecie",
  "Co z demokracją w czasach epidemii?",
  "Rekordowa nadwyżka w budżecie państwa",
  "Grubsze portfele Polaków",
  "Inwestycje napędzają polską gospodarkę",
  "Rosną dochody polskich rodzin",
  "Polska w światowej elicie gospodarczej",
  "Siła polskiej gospodarki budzi zazdrość",
  "Bezrobocie najniższe w historii",
  "Inwestorzy docenili prospołeczny budżet",
  "Polska gospodarka rośnie dzięki inwestycjom",
  "Morze źródłem potęgi polskiej gospodarki",
  "Polska gospodarka łapie wiatr w żagle",
  "Zagraniczni inwestorzy stawiają na Polskę",
  "Polska uniezależnia się od obcego kapitału",
  "Smutny raport ze świata",
  "Oszustwo na koronawirusa",
  "Pełna mobilizacja w walce z epidemią",
  "Wspieraj polskie firmy, ratuj miejsca pracy",
  "Dziwna amnezja polityków opozycji",
  "Epidemia nie może zniszczyć demokracji",
  "Komu przeszkadzają symbole polskości?",
  "Komu podobają się podwójne standardy?",
  "Komu zależy na podsycaniu ulicznych emocji?",
  "Komu przeszkadza sojusz polsko-amerykański?",
  "Komu przeszkadza Sylwester w Zakopanem?",
  'Komu przeszkadza "Sylwester Marzeń" TVP?',
  "Komu zależy na wywołaniu paniki?",
  "Komu zależy na chaosie w czasie epidemii?",
  "Porażka przeciwników reformy sądów",
  "Ideologia ważniejsza od bezpieczeństwa",
  "Dziedzictwo Świętego Jana Pawła II",
  "Modlitwa w czasach zarazy",
  "Mobilizacja w walce z pandemią",
  "Koronawirus zainfekował gospodarkę",
  "Rośnie tragiczny bilans pandemii",
  "Opozycja chce chaosu w czasie kryzysu",
  "Polacy razem w walce o zdrowie",
  "Gazeta Wyborcza obraża ofiary pandemii",
  "Druga faza kryzysu - krach gospodarczy",
  "Opozycja obraziła się na demokrację?",
  "Szokujący popis bezmyślności",
  "Na froncie wojny z niewidzialnym wrogiem",
  "Apel do Polaków: Zostańcie w domach!",
  "Wspólnie postawmy tamę pandemii",
  "Polsce nie grozi blackout",
  "Opozycja opóźnia pomoc dla Polaków",
  "Zachód zazdrości Polsce dobrobytu",
  "Opozycja bezradna wobec kryzysu",
  "Specjalna oferta TVP dla Polaków",
  "Partyjny bełkot to nie informacja",
  "Bruksela bezradna wobec epidemii",
  "Pełna determinacja w walce z epidemią",
  "Ułatwienia i miliardy złotych dla Polaków",
  'Operacja "Odporna wiosna"',
  "Opozycja igra z bezpieczeństwem Polaków",
  "Rozrywka z TVP w czasie kwarantanny",
  "Wirus zaraża osoby niewierzące",
  "Koronawirusa nie ma w kościele",
  "Koronawirus w poniemieckich miastach",
  "Przez koronawirusa wierni nie przychodzą na msze",
  "Chory z Zielonej Góry nie miał chrztu",
  "Czy Zielona Góra to tak naprawdę polskie miasto?",
  "Andrzej Duda nakrzyczał na Niemcy",
  "Sąd najwyższy niszczy praworządność",
  "Sąd najwyższy niszczy państwo Polskie",
  "Sąd najwyższy złamie polską konstytucję?",
  "Kampania nienawiści Kidawy-Błońskiej?",
  '"Solidarność" apeluje o wsparcie TVP',
  "Znamy przeciwników biało-czerwonych",
  "Pełna gotowość do walki z koronawirusem",
  "Niemcy zazdroszczą Polakom odporności",
  "Polacy genetycznie odporni na koronawirusa",
  "Obecnie Polsce zagraża jedynie Donald Tusk",
  "Koronawirus dowodem na niegodziwość Tuska",
  "PO nic nie zrobiło z koronawirusem przez 8 lat",
  "Panika w supermarketach",
  "Rząd zapewnia: Nie ma powodu do niepokoju",
  "Rząd: Jesteśmy przygotowani",
  "Znany doktor zaleca wlewy z perhydrolu",
  "Czas oczekiwania na SOR-ach: 8 miesięcy",
  "Zamieszki w Warszawie, Łodzi, Krakowie i Toruniu",
  "Woda i powietrze nośnikiem wirusa?",
  "Rząd zawierza opiekę Matce Boskiej i JP II",
  "Koronawirus w Polsce to wina poprzedniej władzy",
  "W Polsce nie ma zagrożenia koronawirusem",
  "Duda: Będzie wsparcie dla seniorów",
  "Duda: Gwarantuję pieniądze dla Polaków",
  "Duda: Musimy obronić 500+",
  "Sędziowska kasta ponad prawem",
  "Hejterzy atakują rząd",
  "Polska gospodarka coraz silniejsza",
  "Ukraińcy z Niemcami mordowali Polaków",
  "Świat walczy z epidemią",
  "Opozycja chce nowej terapii szokowej?",
  "Polska wschodnia odzyskuje blask",
  "Marszałek senatu brał łapówki?",
  "Kolejny świadek: Grodzki wziął łapówkę",
  "Nowy świadek w sprawie Grodzkiego",
  "Kontrowersje wokół marszałka senatu",
  "Pytania o fundację Grodzkiego",
  "Świadek: Dałem łapówkę dla Grodzkiego",
  "Kolejna osoba oskarża marszałka senatu",
  "Grodzki i nowe zeznania ws. łapówek",
  "Grodzki unika pytań o korupcję",
  "CBA zbada zarzuty wobec Grodzkiego",
  "Kulisy majątku Grodzkiego",
  "Prezes Kaczyński objawił się Matce Boskiej",
  "Ponad 11 miliardów zł na onkologię",
  "Dodatkowe miliardy dla chorych na raka",
  "Rząd: 10 mld zł na onkologię w 2020",
  "Nowa strategia onkologiczna",
  "Więcej pieniędzy na onkologię",
  "Dzięki PiS transport zaczął się opłacać",
  "Tomasz H. przejechał kolejną staruszkę",
  "Walka z chińskim koronawirusem",
  "Sejm wsparł misję mediów publicznych",
  "Atak opozycji za walkę z korupcją?",
  "Hejterzy chcą uczyć elegancji",
  '"Zenek" od dziś w kinach!',
  "Partia hejtu atakuje posłankę Prawa i Sprawiedliwości",
  "PiS ofiarą kampanii nienawiści",
  "Polska centrum globalnej debaty",
  "Groźny wirus paraliżuje świat",
  'Robert Kubica wraca na "start"',
  "Kampania chamstwa i pogardy",
  "Raport NIK obciąża rządy PO-PSL",
  "Tajemnice majątku Kwaśniewskich",
  "Opozycja nie zabierze pieniędzy emerytom",
  "Sukces służb w walce z mafią podatkową",
  "Sondaż: Konstytucja ważniejsza od prawa UE",
  "Brudna kampania Kidawy-Błońskiej",
  "Polityczny atak na telewizję polską",
  "Komu przeszkadza dobrobyt Polaków?",
  "Opozycja razem z Putinem przeciw Polsce",
  "Sędziowska kasta krzywdzi Polaków",
  '"Nic się nie stało" wkrótce w TVP',
  "Szkolnictwo: Języki obce tylko po polsku",
  "Niemcy zazdroszczą Polsce braku koronawirusa",
  "Koronawirus metodą odwrócenia od sprawy sądów",
  "Potfele Polaków pękają w szwach",
  "Komu przeszkadza pizza hawajska?",
  "Polskie lotniskowce wypłynęły z Radomia do Iranu",
  "TVP przeprasza za manipulacje",
  "Fiskus zbiera więcej pieniędzy niż Owsiak!",
  '"Ekologizm" - nowa totalitarna ideologia?',
  "Putin szkaluje Polskę",
  "Zenek Martyniuk - Chopin naszych czasów",
  "Opozycja chce zablokować rozwój Orlenu",
  "Marek Suski intelektualnym filarem rządu",
  "TVN sieje nienawiśc i obraża naszą redakcję!",
  "Nieważne kto głosuje, ważne, kto liczy głosy",
  "Po co zmiany, skoro jest tak świetnie?",
  "Znamy już wyniki wyborów prezydenckich",
  "Kaczyński: Zbudujemy państwo dobrobytu",
  "Polacy stawiają na Prawo i Sprawiedliwość",
  "Lewicowa ideologia bije w rolników",
  "Debata o przyszłość Europy",
  "Inwazja LGBT pod hasłem tolerancji",
  "Strategia pustych obietnic opozycji",
  "Samuei Pereira dziennikarzem roku",
  "Niezwykły tydzień dobrych wiadomości",
  "Krytyka Polski to obsesja brukselskich elit",
  "Gdyby A. Nobel żył, poparłby dobrą zmianę",
  'Polski "Cud gospodarczy" budzi podziw',
  "Rosną inwestycje w służbę zdrowia",
  "Koalicja obywatelska chce zabrać 500+?",
  "Kampanijne sztuczki Koalicji Obywatelskiej",
  "Opozycja kpi z ciężko chorych Polaków",
  "Wyborcze przepychanki opozycji",
  "PiS zyskuje, Koalicja Obywatelska traci",
  "Platforma nienawiści",
  "Dziennikarze czy aktywiści opozycji?",
  "Problemy Konfederacji",
  "Opozycja gra pieniędzmi dla polskiej wsi",
  "Opozycja zapowiada wzrost cen prądu",
  "Lider Konfederacji chce sojuszu z Rosją",
  "Obietnice bez (PO)krycia?",
  "Zwolennicy opozycji gardzą Polakami?",
  "Polska najbezpieczniejsza w historii",
  "Polska bezpieczna jak nigdy w historii",
  "Platforma Obywatelska stosuje propagandę nienawiści",
  "Bombelki szczęśliwe jak nigdy dotąd",
  "PiS spełnia marzenia Polaków",
  "Dotyk Prezesa uzdrawia z koronawirusa",
  "Gdyby rządziło PO, chorych byłoby więcej",
  "Polacy nie chcą powrotu Tuska",
  "Szumowski: Trzeba ratować polskie szpitale",
  "Ateiści chcą odebrać Polakom 500+",
  "Początkujący lekarze żądają miliardów złotych",
  "Komu przeszkadzają paski w TVP?",
  "Polacy wybierają Prawo i Sprawiedliwość",
  "Duda prezydentem prawdziwych Polaków",
  "Próba zamachu na praworządność",
  "Modlitwa drogą do pokonania epidemii",
  "Wybory można przeprowadzić w Żabkach",
  "Opozycja chce nielegalnie przełożyć wybory",
  "Dlaczego opozycja atakuje Pocztę Polską?",
  "Błąd na kartach wyborczych - DÜDA zamiast DUDA",
  "Stalin: nikt nie będzie sądził zwycięzców",
  "Polacy ocenili pracę rządzących. Najlepiej wypadł premier",
  "100-latek z Warszawy pokonał koronawirusa",
  "Tarcza antykryzysowa nie ograniczy praw pracowników",
  "Fermy norek zamknięte. Zwierzęta mają koronawirusa",
  "Kot zatrzasnął drzwi balkonowe Prezesowi",
  "Poczta Polska nieustannie pracuje i wypełnia swoją rolę",
  "Stalowa Wola za wyborami w maju",
  "Poczta Polska gotowa do wyborów",
  "Chińska komunistyczna propaganda a koronawirus",
  "Sondaż: Duda i długo, długo nikt",
  "Zwolennicy opozycji dehumanizują wyborców PiS",
  "Msza św. w czasie epidemii – transmisje online w TVP",
  "Policja dba o ludzi",
  "Dzieci dziękują policji za pracę",
  "Policjanci rozdają mieszkańcom maseczki",
  "Uśmiech bombelka wart więcej niż 500+",
  "Zgniły zachód chce upadku Wielkiej Polski",
  "LEWACKIE OBWE ATAKUJE PRAWDZYWYCH POLAKÓW",
  "Komu przeszkadzają polskie wybory?",
  "Tylko My Polacy!",
  "Jest tylko jedna racja, i my ją mamy!",
  "Racja PiSu jest racją wszystkich Polaków",
  "Opozycja blokuj kampanię Dudy!",
  "Komu przeszkadzają demokratyczne wybory?",
  "Polska Wenezuelą Europy!",
  "Unia straszy Polskę za wiarę!",
  "Sukces oglądalności telewizji polskiej",
  "Naród Polski chce wybrać prezydenta",
  "Komu przeszkadzają egzekucje liderów opozycji?",
  "Nie ma zgody na prywatyzację szpitali",
  "Niejasne powiązania opozycji z UPC",
  "Północnokoreańska telewizja wzoruje się na TVP",
  "Kot Prezeza za Andrzejem Dudą",
  "Jan Paweł II był wielkim człowiekiem",
  "Ekspert odpowiada na pytania widzów TVP",
  "Koronawirus pod kontrolą rządu",
  "Protestujący wspierani przez opozycję",
  "Nie brak opinii, że wirus to spisek opozycji",
  "Krematoria nie nadążają z paleniem zwłok",
  "PiS daje, PO kradło",
  "Sondaż: A. Duda wygrywa w pierwszej turze",
  "Opozycja cofa się do tyłu",
  "Każdy Polak zarabia powyżej średniej",
  "Wynagrodzenia rosną jak na drożdżach",
  "Garb afer ciąży opozycji",
  "Prawo i Sprawiedliwość dotrzymuje słowa",
  "Kolejki do lekarza stały się przeszłością",
  "Brutalny zamach opozycji na TVP",
  "Działacze opozycji pójdą do piekła",
  "Szanuj nauczyciela swego, bo możesz mieć telewizyjnego",
  "Sukces planu Jarosława Kaczyńskiego",
  "Musimy obronić demokrację w czasie zarazy",
  "Czy ITI powstało na zlecenie komunistycznych służb PRL?",
  'Medialne ataki "realnej" opozycji',
  "Polacy czekają na ratunek, opozycja debatuje",
  "Marszałek Grodzki lekceważy zagrożenie",
  "Kurski: KOCHAM TELEWIZJĘ POLSKĄ I POLSKĘ",
  "Opozycja gra koronawirusem",
  "Krytyka zamiast programu",
  "Polskie firmy rosną w siłę",
  "Narodowe czytanie Pisma Świętego",
  "Polacy wygrywają z koronawirusem",
  "Fake newsy przeciwników polskiego rządu",
  "Polacy wygrywają wojnę z koronawirusem",
  "TVP Info - Główne źródło informacji",
  "Sąd Najwyższy niszczy państwo prawa",
  "Polska walczy o każde miejsce pracy",
  "Węgrzy oburzeni słowami Tuska",
  '"Terytorialsi" walczą z wirusem',
  "Wybory tajne i bezpieczne",
  "Unijne kary za ochronę Polaków?",
  "Opozycja chce przedwczesnych decyzji",
  "Skuteczna walka z koronawirusem",
  "Rzad zdaje test",
  "Polacy zaufali telewizji polskiej",
  "Wierni pozostali w domach",
  "Polski rząd nie marnuje czasu",
  "TVP bliżej dzieci i młodzieży",
  "Marszałek oblał test z odpowiedzialności",
  "Kidawa-Błońska nie poparła 500+",
  "Misja mediów publicznych",
  "TVP dla każdego",
  "Rząd realizuje obietnice wyborcze",
  "Polskie służby gotowe na koronawirusa",
  "Opozycja straszy Polaków drożyzną",
  "Zdrowotne hipokryzje opozycji",
  "Polacy krytyczni wobec gry opozycji",
  "Hejterzy niszczą polską demokrację",
  "Sondaż: Polacy chcę reelekcji A. Dudy",
  "Nagroda Gazety Polskiej dla J. Kaczyńskiego",
  "Homolobby żąda prawa do adopcji dzieci",
  "Bojówki opozycji i brudna kampania",
  "Opozycja znowu straszy",
  "Nie ma testów - nie ma chorych",
  "Parlamentarzyści na Jasnej Górze",
  "Młodzi lekarze żądają dużych podwyżek",
  "Rezydenci: 5 tys. zł pensji to za mało",
  "Młodzi lekarze odrzucili kompromis",
  "Morawiecki: Plan Marshalla na miarę XXI wieku",
  "Kaczyński: Nie lękajcie się",
  "Korwin-Mikke: Patrzę na was… Głodnych… Przerażonych!",
  "Koronawirus spiskiem opozycji",
  "Fake newsy przeciwników polskiego rządu",
  "Kto tyle dał co PiS, na pewno nie PO",
  "Polska radzi sobie z kryzysem najlepiej spośród państw UE",
  "Kościół przebiera chłopców w sukienki",
  "Promocje w Lidlu i Biedronkach zagrożeniem dla budżetu",
  "Duda walczy o ponowną erekję",
  "Rząd pożycza pieniądze od bociana",
  "Andrzej Duda na Tik Toku!",
  "Polacy zamiast w bitcoiny inwestują w obligacje",
  "Czy klękać przed telewizorem w trakcie mszy w TV?",
  "Koronawirus boi się silnej Polski",
  "Tusk przedarł się przez granicę",
  "Schowek na miotły bezpiecznym miejscem w czasie zarazy",
  "Morawiecki: Podatki na prywaciarzy",
  "Janusz Korwin-Mikke ponownie internowany",
  "Polacy śmieją się ze wzrostu Prezesa",
  "Ksiądz Matusz umarł!",
  "Elon Musk wysyła satelity do Polski",
  "W pakiecie wyborczym maść gratis",
  "Kubki z Dudą dla każdego wyborcy",
  "Szumowski: Po koronawirusie znowu na narty do Włoch",
  "Szkoła z TVP doradza premierowi",
  'Uczniowie lubią "Szkołę z TVP"',
  "Czy maseczki sprowadzone z Chin przez KGHM nie mają certyfikatów jakości?",
  "Czy Covid-19 będzie sezonowo nawracał jak grypa?",
  "Kaczyński: posiadanie kota może zabezpieczać przed koronawirusem",
  "Czy pandemia COVID-19 to jedno wielkie oszustwo?",
  "Zięba: Lewoskrętna witamina C niszczy wirusa",
  "Czy Bill Gates i jego fundacja stoją za wybuchem pandemii Sars-Cov-2?",
  "Czy koty mogą się zakażać koronawirusem od ludzi?",
  "Ból jąder to objaw koronawirusa",
  "Po konwencji Dudy zmarły kolejne osoby",
  "Karty wyborcze trafiają do spamu",
  "Demonstranci pokazują czerwoną kartkę rządowi",
  "Czy Chiny ukrywają prawdę przed WHO?",
  "Liczbę zakażonych ustalają nauczelki z TVP",
  "PiS to jedyna partia, która walczy o demokrację",
  "Andrzej Duda przemawia bez promptera!",
  "Platforma chce wywołać kryzys polityczny?",
  "Większość Polaków pozytywnie ocenia działania rządu",
  '"Polska, tu mi dobrze"',
  "Polacy odmawiają Koronkę do Bożego Miłosierdzia",
  "Komu zależy na tym żeby demokratyczne wybory się nie odbyły?",
  "Gniazdo Platformy, trzeba je zniszczyć",
  "Jesteśmy poważną telewizją",
  "Andrzej Duda zbudował Baltic Pipe",
  "Andrzej Duda prezydentem roku!",
  "Opozycja chce stanu nadzwyczajnego z powodu słabych sondaży",
  "Świat uczy się demokracji od Polski",
  "Wakacji nie będzie, będą wybory",
  "Sasin: Kiedy redaktorzy obniżą sobie pensje?",
  "Tłumy na koncertach to dziennikarze TVP",
  "Rząd zjednoczonej prawicy dofinansowuje strażaków",
  "Prezydent żartował na zaprzysiężeniu",
  'Znany youtuber: "W Polsce jak w lesie"',
  "Rząd rejestruje nakazuje rejestrację drukarek aby ludzie nie drukowali własnych kart wyborczych",
  "Rząd przechowuje PESEL-e w plikach .txt",
  "Dykta najdroższym surowcem w Polsce",
  "Polacy podpisują oświadczenia że nie patrzą na sąsiadów",
  "Pakiety wyborcze odblokowują nową emotkę na Facebooku",
  "Deszcze w Polsce zasługą modlitw wiernych",
  "Badania niepotrzebnie psują statystyki",
  "Czy my żyjemy w Bantustanie?",
  'Rząd: Mamy "kwity" na każdego!',
  "Twardy elektorat zniesie wszystko",
  "Komu przeszkadzają niewygodne pytania?",
  "Opozycja chce blokować niezależne dziennikarstwo",
  "Komu przeszkadzają pytania o intymne relacje?",
  "Polacy skarżą się na Polskę",
  "Zamiast u fryzjera, w kościołach strzygą co łaska",
  "Poparcie dla PiS-u i Dudy wyższe niż przed epidemią",
  "Życie emerytów ważniejsze niż gospodarka kraju",
  "Komu przeszkadzają chemtrails?",
  "Ministerstwo opiera swoje modele o funkcję Math.random()",
  "Instytut Danych z D. przesyła analizy do Ministerstwa Zdrowia",
  "Duda: Obronimy Polskę Plus!",
  "Duda: Najpierw 14, potem 15 emerytura!",
  'Nowy serial "Zaczarowany Ołówek" na antenie TVP',
  "Testy w pierwszej kolejności dla posiadaczy paszportu Polsatu",
  "Reporterzy TVN-u ganiają rowerzystów",
  "Polacy odpoczywają, gospodarka zyskuje!",
  "Filip Chajzer miał kolejny atak paniki",
  "Solidarność popiera Andrzeja Dudę",
  "Gdyby Platforma rządziła, połowa ludzi by powymierała",
  "Polacy chcą iść do wyborów na kolanach",
  "Brak porozumienia w Porozumieniu",
  "Polska liczy przypadki szybciej niż Niemcy",
  "Po co komu opozycja?",
  "Jarosław Kaczyński nominowany do #hot16challenge2",
  "Klocuch nominowany na ministra cyfryzacji",
  "Czy senat został założony przez służby PRL?",
  "Słupki z poparciem Dudy wystają poza ekran",
  "Łubu dubu, niech nam żyje Prezes klubu",
  "Ceny świń pikują w dół",
  "Od dziś 300+ za każdego posiadanego kota",
  "Bezrobocie spada - głodni zjadają bezrobotnych",
  "Poparcie dla PiS 50 razy większe niż 100%",
  "Klęska opozycji, sukces demokracji",
  "Demokracja przeszkadza opozycji?",
  "Dziś we Wrocławiu nie wykoleił się żaden tramwaj",
  "Opozycja chce siłą przejąć władzę?",
  "Korwin chce wieszać beneficjentów 500+",
  "Stąd komuchy wyszli",
  "Opozycja walczy z ostrym cieniem mgły",
  "Polacy są zgodni, Duda najlepszy #hot16challenge",
  "Opozycja próbuje wywołać chaos polityczny",
  "Opozycja chce wywołać wyborczy chaos",
  "Duda uzbierał 1,5 mln zł na zbiórce hip-hopowej",
  "Słynne paski nazywane paskami grozy w TVN-ie",
  "Poparcie PiS widać z Księżyca",
  "Komu przeszkadza dyktatura?",
  "Coraz więcej Polaków stać na chleb",
  "Prezes Kaczyński był na otwarciu. Czego?",
  "Trwa walka z ostrym cieniem mgły",
  "Kaczyński sam wyzwolił Polskę od komuny",
];
