export const SECOND_WAVE_DAILY_REPORTED = [
  0, 0, 0, 0, 0, 1, 0, 4, 1, 5, 6, 5, 9, 20, 17, 36, 21, 52, 61, 49, 68, 70,
  111, 98, 116, 152, 150, 170, 168, 249, 224, 193, 256, 243, 392, 437, 244, 475,
  311, 435, 357, 370, 380, 401, 318, 260, 268, 380, 336, 457, 334, 515, 306,
  263, 313, 342, 381, 381, 344, 285, 316, 422, 300, 228, 270, 318, 313, 406,
  311, 303, 337, 288, 345, 330, 556, 322, 411, 401, 241, 272, 356, 382, 471,
  403, 472, 316, 361, 341, 443, 396, 352, 333, 412, 219, 374, 236, 292, 361,
  362, 576, 575, 599, 400, 282, 359, 376, 440, 375, 396, 407, 450, 314, 352,
  309, 311, 296, 300, 294, 298, 276, 319, 193, 247, 239, 382, 371, 259, 314,
  231, 205, 257, 277, 262, 265, 305, 370, 299, 267, 264, 333, 353, 339, 358,
  279, 399, 380, 418, 458, 584, 443, 337, 502, 512, 615, 657, 658, 548, 575,
  680, 640, 726, 809, 843, 624, 619, 551, 715, 811, 825, 778, 594, 595, 597,
  735, 767, 903, 900, 581, 548, 763, 729, 887, 791, 759, 631, 502, 550, 595,
  612, 691, 567, 437, 302, 400, 421, 506, 594, 603, 502, 377, 605, 600, 837,
  757, 1002, 910, 748, 711, 974, 1136, 1587, 1584, 1350, 1306, 1326, 1552, 1967,
];

export const SECOND_WAVE_DAILY_RECOVERED = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 2, 0, 0, 0, 1,
  6, 9, 1, 3, 5, 7, 0, 12, 0, 0, 69, 18, 28, 29, 31, 62, 34, 57, 64, 48, 131,
  50, 106, 92, 115, 59, 93, 164, 216, 227, 204, 182, 139, 201, 189, 370, 211,
  255, 271, 183, 150, 185, 375, 207, 322, 253, 261, 118, 315, 279, 286, 222,
  257, 276, 177, 275, 280, 269, 279, 246, 217, 82, 744, 310, 230, 132, 324, 255,
  178, 277, 288, 213, 183, 231, 214, 143, 198, 215, 285, 109, 299, 122, 157,
  271, 267, 396, 381, 483, 502, 393, 497, 561, 520, 564, 754, 576, 349, 384,
  510, 418, 442, 476, 619, 220, 272, 640, 599, 571, 587, 513, 367, 241, 736,
  436, 577, 419, 368, 247, 232, 368, 402, 456, 422, 334, 103, 187, 147, 453,
  344, 387, 335, 172, 175, 265, 321, 399, 362, 288, 186, 273, 461, 350, 401,
  491, 277, 229, 284, 456, 382, 548, 632, 522, 265, 336, 615, 698, 688, 751,
  656, 446, 392, 835, 728, 1227, 2526, 1910, 857, 797, 1225, 934, 779, 877, 934,
  438, 451, 562, 615, 587, 549, 441, 302, 368, 589, 597, 582, 586, 578, 516,
  535, 740, 706,
];

export const SECOND_WAVE_DAILY_DEATHS = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 1, 1, 0, 0, 0, 0, 2, 1, 2,
  3, 3, 0, 2, 4, 9, 2, 10, 14, 14, 8, 15, 13, 22, 29, 16, 7, 27, 24, 13, 18, 23,
  28, 18, 15, 13, 20, 20, 25, 28, 40, 30, 12, 27, 34, 28, 20, 7, 12, 15, 19, 18,
  18, 22, 21, 9, 15, 11, 27, 23, 22, 24, 8, 10, 11, 12, 14, 10, 10, 11, 3, 11,
  17, 4, 10, 13, 10, 3, 10, 18, 23, 2, 20, 14, 4, 9, 19, 23, 9, 7, 15, 10, 9,
  16, 14, 30, 18, 12, 10, 3, 16, 21, 16, 17, 6, 4, 6, 19, 14, 15, 15, 5, 5, 4,
  7, 14, 8, 11, 6, 3, 5, 12, 6, 11, 7, 6, 6, 3, 9, 6, 9, 4, 9, 7, 5, 6, 12, 15,
  7, 5, 10, 1, 6, 18, 18, 13, 13, 7, 2, 12, 9, 14, 14, 11, 8, 8, 11, 17, 12, 13,
  13, 4, 5, 17, 17, 16, 8, 14, 1, 6, 19, 20, 14, 8, 13, 7, 4, 12, 11, 12, 10,
  13, 6, 15, 24, 10, 16, 17, 12, 11, 5, 18, 28, 25, 23, 32, 8, 15, 36, 30, 30,
];

export const enabled = false;
